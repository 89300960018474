<template>
	<div class="dashboard">
		<div class="dashboard__body">
			<div class="boxes">
				<template v-for="(item, index) in items">
					<router-link
						v-if="
							checkModule({
								moduleName: item.moduleName,
								pageName: item.pageName,
							})
						"
						:to="{ name: item.to }"
						:key="index + '_route'"
						:style="{ position: 'relative' }"
					>
						<div
							class="text"
							:style="{
								position: 'relative',
							}"
						>
							<v-icon>{{ item.icon }}</v-icon>
							<div class="text__title" v-text="item.title"></div>
							<div
								class="text__description"
								v-text="item.description"
							></div>
							<v-btn
								height="26"
								width="26"
								color="red"
								fab
								dark
								small
								class="amountNotify"
								v-if="item.to == 'AgreementNotification'"
							>
							</v-btn>
						</div>
					</router-link>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'SupplierHomeView',
	data() {
		return {
			items: [
				{
					to: 'SupplierNotification',
					title: 'Notificaciones',
					description: '',
					moduleName: 'Proveedor',
					pageName: 'ListaNotificaciones',
					icon: 'mdi-bell-outline',
				},
			],
		};
	},
	created() {},
	computed: {},
	methods: {
		checkModule({ moduleName = '', pageName = '' }) {
			let found = false;
			const activities = this.$store.state.auth.activities || [];
			if (Array.isArray(activities)) {
				found = activities.some((activity) => {
					return (
						activity.module_name == moduleName &&
						activity.page_name == pageName
					);
				});
			}
			return found;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/dashboardHome.scss';
</style>
<style lang="scss">
.amountNotify {
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(-50%, -70%);
}
</style>
